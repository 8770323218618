<template>
  <div class="container">
    <el-image
      class="banner"
      :src="require('@as/event/join/banner.png')"
    ></el-image>
    <div class="main">
      <div class="left">
        <div class="tips">
          汇律网尊重并保护所有使用汇律网电子日刊/周刊用户的个人隐私权。将对您填写的信息采取严密保护措施，不会主动泄漏给第三方。清科有可能使用这一信息与您进行联系以便您了解相关产品及服务，如果您不同意汇律网接收其他服务信息，请在此标注。
        </div>
        <div class="form">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="90px"
            class="demo-ruleForm"
          >
            <el-form-item label="订阅邮箱" prop="email">
              <div class="mark">*</div>
              <el-input v-model="ruleForm.email" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="订阅类别">
              <div class="mark">*</div>
              <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox
                  label="周刊中文版【样刊】"
                  name="type"
                ></el-checkbox>
                <el-checkbox label="周刊英文版" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <div class="mark">*</div>
              <el-input v-model="ruleForm.name" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="公司" prop="company">
              <div class="mark">*</div>
              <el-input
                v-model="ruleForm.company"
                placeholder="公司"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <div class="mark">*</div>
              <el-input
                v-model="ruleForm.phone"
                placeholder="13112341234"
              ></el-input>
            </el-form-item>
            <el-form-item label="职位" prop="job">
              <div class="mark">*</div>
              <el-input v-model="ruleForm.job" placeholder="职位"></el-input>
            </el-form-item>

            <el-form-item label="机构类型">
              <div class="mark">*</div>

              <el-select v-model="ruleForm.insType" placeholder="机构类型">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地区">
              <div class="mark">*</div>

              <el-select v-model="ruleForm.city" placeholder="北京">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
              <el-select v-model="ruleForm.city" placeholder="东城区">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
            <div class="read">
              <el-checkbox v-model="ruleForm.read"
                >汇律网尊重并保护所有使用汇律网电子日刊/周刊用户的个人隐私权。将对您填写的信息采取严密保护措施，不会主动泄漏给第三方。清科有可能使用这一信息与您进行联系以便您了解相关产品及服务，如果您不同意汇律网接收其他服务信息，请在此标注。
              </el-checkbox>
            </div>

            <div class="submit_btn">提交</div>
          </el-form>
        </div>
      </div>
      <div class="right">
        <div class="head">
          <div class="title">
            <el-image
              class="title_icon"
              :src="require('@as/event/join/icon_1.png')"
            ></el-image>
            <div>合作咨询</div>
          </div>
          <div class="title_more">MORE</div>
        </div>
        <div class="refer">
          <div class="row last" v-for="item in 3" v-bind:key="item">
            <el-image
              class="img"
              :src="require('@as/serve/knowledge/banner.png')"
            ></el-image>
            <div class="info">
              <div class="title">媒体合作咨询</div>
              <div class="subtitle">姓名：陈俊</div>
              <div class="subtitle">电话：2485</div>
              <div class="subtitle">邮箱：2485@163.com</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        email: '',
        name: '',
        phone: '',
        type: '',
        company: '',
        job: '',
        city: '',
        read: '',
        insType: '',
      },
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  margin: 0 auto 55px;
  width: 1200px;
  .banner {
    width: 1200px;
    height: 240px;
  }
  .main {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 1200px;
    .left {
      padding: 48px 87px;
      width: 850px;
      box-sizing: border-box;
      background: #fff;
      .tips {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        line-height: 20px;
      }
      .form {
        margin-top: 50px;
        & /deep/ .el-form-item {
          margin-bottom: 30px;
        }
        & /deep/ .el-form-item__label {
          text-align: left;
          font-size: 13px;
          color: #595757;
        }
        & /deep/ .el-form-item__content {
          display: flex;
        }
        & /deep/ .el-checkbox {
          margin-right: 50px;
        }
        & /deep/ .el-select {
          margin-right: 4px;
        }
        .read {
          margin-left: 90px;
          & /deep/ .el-checkbox {
            display: flex;
            white-space: normal;
          }
          & /deep/ .el-checkbox__input {
            margin-top: 4px;
          }
          & /deep/ .el-checkbox__input.is_focus .el-checkbox__inner {
            border-color: #b7b8b8;
          }
          & /deep/ .el-checkbox__inner:hover {
            border-color: #b7b8b8;
          }
          & /deep/ .el-checkbox__inner::after {
            border-color: #595757;
          }
          & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #d5d6d6;
            border-color: #b7b8b8;
          }
          & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
            border-color: #b7b8b8;
          }
          & /deep/ .el-checkbox__input.is-checked + .el-checkbox__label,
          & /deep/ .el-checkbox__label {
            color: #afafaf;
            font-size: 13px;
            line-height: 20px;
          }
        }
        .mark {
          margin-right: 26px;
          color: #c6091a;
        }
        .submit_btn {
          margin-top: 40px;
          padding: 10px 305px;
          color: #fff;
          background: #1c9ab1;
        }
      }
    }
    .right {
      padding: 20px 27px;
      flex-shrink: 0;
      width: 330px;
      background: #fff;
      box-sizing: border-box;
      .head {
        display: flex;
        justify-content: space-between;
        .title {
          padding-bottom: 6px;
          display: flex;
          align-items: center;
          font-size: 18px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border-bottom: 2px solid #4a97ad;
          .title_icon {
            margin-right: 5px;
            width: 22px;
            height: 20px;
          }
        }
        .title_more {
          padding-bottom: 6px;
          flex: 1;
          font-size: 10px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #a6a7a7;
          text-align: right;
          border-bottom: 1px solid #dedede;
        }
      }
      .refer {
        .row {
          padding: 17px 6px;
          display: flex;
          align-items: flex-start;
          &:not(:last-child) {
            border-bottom: 1px solid #dedede;
          }
          .img {
            margin-right: 15px;
            flex-shrink: 0;
            width: 42px;
            height: 42px;
            border-radius: 50%;
          }
          .info {
            .name {
              font-size: 14px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #666666;
            }

            .title {
              margin: 10px 0 12px;
              font-size: 15px;
              font-family: PingFangSC;
              font-weight: 500;
              color: #595757;
            }
            .subtitle {
              margin-top: 10px;
              font-size: 13px;
              font-family: PingFangSC;
              font-weight: 400;
              color: #a7a8a8;
            }
          }
        }
      }
    }
  }
}
</style>